<template>
	<div
	coring-core-form
	>
		<div class="tw-flex tw-flew-row tw-items-end">
			<h2 class="tw-mr-[10px]">
				Carotte #{{ editingCore.number }}
			</h2>

			<div class="tooltip tw-flex tw-flex-col tw-overflow-hidden tw-h-full tw-justify-between">
				<div class="text-ellipsis tw-flex tw-flex-row tw-items-center">
					<h2
					v-if="editingCore.displayNamePrefix"
					class="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"
					>
								&nbsp;{{ '- '+editingCore.displayNamePrefix.name + editingCore.displayName }}
					</h2>

					<h2
					v-else
					class="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"
					>
						&nbsp;{{ '- '+editingCore.businessId }}
					</h2>
				</div>
				<div class="tooltiptext">
					{{ editingCore.displayNamePrefix ? editingCore.displayNamePrefix.name + editingCore.displayName : editingCore.businessId }}
				</div>
			</div>

			<div
			v-if="editingCore.extractedAt"
			style="font-size: 10px;"
			class="tw-mb-[5px]"
			>
				Prélevée le {{ moment(editingCore.extractedAt).format("DD/MM/Y") }}
			</div>
		</div>

		<section class="tw-grow tw-overflow-y-auto tw-pr-[5px] tw-h-full">
			<CoringCoreForm1
			ref="form1"
			@closeStructuralType="closeStructuralType()"
			@openStructuralType="openStructuralType()"
			/>

			<CoringCoreForm2
			ref="form2"
			v-if="campaignStatus.step >= 3 || editingCore.status === 'sent_to_study_manager' || editingCore.status === 'validated'"
			:isDisabled="campaign.step > 3"
			@closeMethodology="closeMethodology()"
			@openMethodology="openMethodology()"
			@openMaterial="openMaterial()"
			@closeMaterial="closeMaterial()"
			/>
		</section>

		<section>
			<div class="container-button">
				<ButtonSlot
				@click="STOP_EDITING_CORE()"
				_theme="light-gray"
				class="tw-w-[122px]"
				>
					annuler
				</ButtonSlot>

				<ButtonSlot
				@click="submitted"
				_auto-loading
				v-if="
					campaignStatus.step <= 6 &&
						(
							editingCore.status === 'sent_to_study_manager' || 
							editingCore.status === 'empty' ||
							(editingCore.status === 'waiting' && campaign.status !== 'programing_validated') ||
							editingCore.status === 'validated' ||
							editingCore.status === 'results_acquired' ||
							editingCore.status === 'sent_to_provider'
						)
				"
				>
					valider
				</ButtonSlot>
			</div>

			<div class="container-button tw-mt-[10px]">
				<ButtonSlot
				v-if="campaign.status === 'awaiting_validation' && $hasRight('campaigns.editCampaignBtn')"
				@click="removeCore"
				_theme="light-gray"
				:_popup="{
					title: 'Supprimer la carotte?',
					subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
				}"
				class="tw-w-[122px]"
				>
					supprimer
				</ButtonSlot>
			</div>
		</section>
	</div>
</template>

<script>
import CoringCoreForm1 from "./Coring.Core.Form.1.vue";
import CoringCoreForm2 from "./Coring.Core.Form.2.vue";
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
	name: "CoringCoreForm",
	components: {
		CoringCoreForm1,
		CoringCoreForm2
	},
	props: {

	},
	data(){
		return {
            
		};
	},
	computed: {
		...mapGetters("coring", [
			"editingCore", "campaign", "campaignStatus"
		]),
	},
	methods: {
		...mapMutations("coring", ["STOP_EDITING_CORE"]),
		...mapActions("coring", [
			"updateCore", "deleteCore", "updateCores"
		]),
		openMethodology(){
			this.$emit("displayMethodology");
		},
		closeMethodology(){
			this.$emit("cancelDisplayMethodology");
		},
		openMaterial(){
			this.$emit("displayMaterial");
		},
		closeMaterial(){
			this.$emit("cancelDisplayMaterial");
		},
		openStructuralType(){
			this.$emit("displayStructuralType");
		},
		closeStructuralType(){
			this.$emit("cancelStructuralType");
		},

		async removeCore(){
			await this.deleteCore(this.editingCore);
			this.STOP_EDITING_CORE();
		},

		async submitted(){
			await new Promise((resolve) => setTimeout(resolve)); // Simule une petite attente

			if(this.campaign.status === "awaiting_validation"){
				// Validation pour le premier formulaire uniquement
				const result = await this.$refs.form1.submit();
				if(result === false){
					return; // Arrête l'exécution si le formulaire 1 n'est pas valide
				}

				this.updateCore({...result, status: "waiting"});
				this.STOP_EDITING_CORE();
			}
			else if(this.campaign.status === "taken" || this.editingCore.status !== "awaiting_validation"){
				// Validation pour les deux formulaires
				const result1 = this.$refs.form1 ? await this.$refs.form1.submit() : null;
				const result2 = this.$refs.form2 ? await this.$refs.form2.submit() : null;

				if(result1 === false){
					this.$toast("warning", "Le formulaire de la carotte n'est pas complet");
					// Si le formulaire 1 n'est pas valide, arrête ici
					return;
				}

				// Vérification si un formulaire est invalide
				if(result1 === false || result2 === false){
					console.error("Validation échouée pour l'un des formulaires.");
					return; // Arrêtez l'exécution si l'un des formulaires est invalide
				}

				// Combine les résultats uniquement si les deux formulaires sont valides
				const result = {
					...result1,
					...(result2 || {}) // Ajoute uniquement les données du formulaire 2 si valide
				};

				this.updateCore({...result});
				this.STOP_EDITING_CORE();
			}
		}


	},
	created(){
        
	},
	mounted(){

	}
    
};
</script>

<style lang="scss">
div[coring-core-form]{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;

    .container-button{
        display: flex;
        justify-content: space-between;
    }
}
</style>
