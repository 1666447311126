<template>
    <div>
        <v-form
            v-if="core && coreLayers && core.status !== 'cancelled'"
            ref="form"
            @submit="e => e.preventDefault()"
            class="tw-grid tw-grid-cols-12 tw-w-full"
        >
            <div class="tw-col-span-12 tw-h-[1px] tw-bg-[gray] tw-rounded-full tw-mb-[5px]" />

            <h2 class="tw-col-span-12 tw-mb-[5px]">
                Données des relevés
            </h2>

            <v-text-field
                class="tw-col-span-12"
                tabindex="1"
                label="Épaisseur de la carotte (en mm)"
                outlined
                clearable
                v-model="core.totalThicknessInMillimeters"
                :rules="[$rules.onlyNumbers]"
                dense
                validate-on-blur
                :disabled="isDisabled"
                ref="totalThickness"
            />

            <v-text-field
                class="tw-col-span-12"
                v-if="campaign.coringType === 'CS'"
                tabindex="1"
                label="Nombre de morceaux"
                outlined
                clearable
                v-model="core.piecesNumber"
                :rules="[$rules.onlyNumbers]"
                dense
                validate-on-blur
                :disabled="isDisabled"
                ref="piecesNumber"
            />

            <v-text-field
                class="tw-col-span-12"
                tabindex="1"
                label="Profondeur totale (en mm)"
                outlined
                clearable
                v-model="core.holeDepth"
                :rules="[$rules.onlyNumbers]"
                dense
                validate-on-blur
                :disabled="isDisabled"
                ref="holeDepth"
            />

            <v-textarea
                tabindex="1"
                class="tw-col-span-12"
                dense
                v-model="core.bottomNature"
                label="Nature du fond de trou"
                rows="1"
                :rules="[$rules.max255()]"
                counter="255"
                outlined
                clearable
                :disabled="isDisabled"
                validate-on-blur
                auto-grow
                ref="bottomNature"
            />

            <v-textarea
                v-if="core.technicianNote !== null"
                tabindex="1"
                class="tw-col-span-12"
                dense
                v-model="core.technicianNote"
                label="Note du technicien"
                rows="1"
                :rules="[$rules.max255()]"
                counter="255"
                outlined
                clearable
                :disabled="isDisabled"
                validate-on-blur
                auto-grow
                ref="technicianNote"
            />

            <div class="tw-col-span-12 tw-h-[1px] tw-bg-[gray] tw-rounded-full tw-mb-[10px] tw-mt-[10px]" />

            <div class="tw-flex tw-flex-col tw-col-span-12">
                <div
                    v-for="layer of coreLayers"
                    :key="layer.number"
                    class="tw-w-full tw-grid tw-grid-cols-12 tw-gap-y-[10px]"
                    :style="{'order': layer.number}"
                >
                    <div
                        class="tw-col-span-12 tw-grid-cols-12"
                        v-if="layer.number > 1 && coreInterfaces[layer.number-2] !== undefined"
                    >
                        <h3 class="tw-col-span-12 tw-col-span-12 tw-mb-[5px]">
                            Interface {{ coreInterfaces[layer.number-2].number }}
                        </h3>

                        <v-select
                            dense
                            tabindex="1"
                            class="tw-col-span-12 tw-mb-[10px]"
                            :items="campaign.isDirMethodology? InterfacesDirStatesField : InterfacesNormalStatesField"
                            :item-text="item => $t(item.name)"
                            :item-value="item => item.name"
                            v-model="coreInterfaces[layer.number-2].state"
                            :rules="[$rules.interfaceState]"
                            label="État de l'interface*"
                            outlined
                            clearable
                            required
                            :disabled="isDisabled"
                            ref="interface_{{ layer.number }}_state"
                        />

                        <v-textarea
                            tabindex="1"
                            dense
                            class="tw-col-span-12"
                            v-model="coreInterfaces[layer.number-2].note"
                            label="Note"
                            rows="1"
                            auto-grow
                            outlined
                            clearable
                            :disabled="isDisabled"
                            :rules="[$rules.max255()]"
                            counter="255"
                            ref="interface_{{ layer.number }}_note"
                        />
                    </div>

                    <h3 class="tw-col-span-12 tw-col-span-12 tw-mb-[5px]">
                        Couche {{ layer.number }}
                    </h3>

                    <v-combobox
                        v-if="campaign.coringType === 'CS'"
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        :items="layerNames"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="layer.layerName"
                        :rules="[$rules.max255()]"
                        counter="255"
                        label="Nom de la couche"
                        outlined
                        clearable
                        :disabled="isDisabled"
                        validate-on-blur
                        ref="layer_{{ layer.number }}_name"
                    />

                    <v-text-field
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        v-model="layer.thickness"
                        label="Épaisseur des couches*"
                        rows="1"
                        outlined
                        clearable
                        required
                        :disabled="isDisabled"
                        :rules="[$rules.onlyNumbers, $rules.required]"
                        validate-on-blur
                        ref="layer_{{ layer.number }}_thickness"
                    />

                    <v-select
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        :items="materialCategories"
                        :item-text="item => item.name"
                        :item-value="item => {return item? {name: item.name, id: item.id} : null}"
                        v-model="layer.materialCategory"
                        :rules="[$rules.atLeastOneMaterial]"
                        label="Catégorie de matériaux*"
                        outlined
                        clearable
                        :disabled="isDisabled"
                        validate-on-blur
                        @change="() => {layer.material = null; setMaterials(layer)}"
                        ref="layer_{{ layer.number }}_material_category"
                    />

                    <v-select
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        outlined
                        clearable
                        :item-text="item => item.name"
                        :item-value="item => {return item? {name: item.name, id: item.id} : null}"
                        :disabled="isDisabled"
                        v-model="layer.material"
                        label="Matériaux*"
                        :items="materialsByLayers[layer.number]"
                        :rules="[$rules.required]"
                        validate-on-blur
                        @change="setCategory(layer)"
                        ref="layer_{{ layer.number }}_material"
                    />

                    <v-select
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        outlined
                        clearable
                        :item-text="item => item.name"
                        :item-value="item => {return item? {name: item.name, id: item.id} : null}"
                        :disabled="isDisabled"
                        v-model="layer.granulometry"
                        label="Granulométrie"
                        :items="granulometriesByLayers[layer.number]"
                        validate-on-blur
                        ref="layer_{{ layer.number }}_granulometry"
                    />

                    <v-text-field
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        v-model="layer.year"
                        label="Année des matériaux"
                        placeholder="Année des matériaux"
                        :rules="[$rules.correctYearFormat]"
                        rows="1"
                        outlined
                        clearable
                        :disabled="isDisabled"
                        ref="layer_{{ layer.number }}_year"
                    />

                    <v-select
                        dense
                        tabindex="1"
                        :class="campaign.isDirMethodology ? 'tw-col-span-10' : 'tw-col-span-12'"
                        :items="campaign.isDirMethodology? dirStatesField : normalStatesField"
                        :item-text="item => $t(item.name)"
                        :item-value="item => item.name"
                        v-model="layer.state"
                        :rules="[$rules.layerState]"
                        label="État de la couche*"
                        outlined
                        clearable
                        required
                        :disabled="isDisabled"
                        ref="layer_{{ layer.number }}_state"
                    />

                    <ButtonSlot
                        v-if="campaign.isDirMethodology"
                        _icon="mdi-help-circle-outline"
                        @click="emitOpenClose()"
                        _theme="light-gray"
                        class="tw-ml-[10px] tw-col-span-2"
                    />

                    <v-textarea
                        tabindex="1"
                        dense
                        class="tw-col-span-12"
                        v-model="layer.note"
                        placeholder="Note additionnelle sur la couche"
                        label="Note"
                        rows="1"
                        outlined
                        clearable
                        :disabled="isDisabled"
                        auto-grow
                        :rules="[$rules.max255()]"
                        counter="255"
                        ref="layer_{{ layer.number }}_note"
                    />

                    <ButtonSlot
                        class="tw-col-span-12 tw-mb-[10px]"
                        _small
                        _theme="light-gray"
                        v-if="!isDisabled"
                        @click="deleteLayer(layer)"
                        :_popup="{
                            title: 'Supprimer la couche?',
                            subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nSi vous validez le formulaire, la couche sera définitivement perdu.',
                        }"
                    >
                        supprimer la couche {{ layer.number }}
                    </ButtonSlot>
                </div>
            </div>

            <div
                v-show="coreLayers.length !== 0 && !isDisabled"
                class="tw-col-span-12 tw-h-[1px] tw-bg-[gray] tw-rounded-full tw-mb-[10px]"
            />

            <div
                v-if="alertHeight"
                class="tw-col-span-12 tw-mb-[10px]"
                style="color: red"
            >
                <h4 class="tw-text-center">
                    L'épaisseur cumulée des couches est supérieure à celle de la carotte !
                </h4>
            </div>

            <ButtonSlot
                v-if="!isDisabled"
                class="tw-col-span-12 tw-mb-[10px]"
                @click="addLayer"
            >
                ajouter une couche
            </ButtonSlot>

            <ButtonSlot
                v-if="!isDisabled && otherCores"
                class="tw-col-span-12 tw-mb-[10px]"
                @click="displayModal = true"
            >
                Copier les couches sur d'autres carottes
            </ButtonSlot>

            <CoringCopy
                v-show="displayModal"
                :core="core"
                @closeCurrent="displayModal = false"
            />
        </v-form>
    </div>
</template>

<script>
import pick from 'lodash.pick';
import { mapGetters, mapActions } from 'vuex';
import CoringCopy from './CoringCopy.popin.vue';

export default {
    name: 'CoringCoreForm2',
    components: {
        CoringCopy,
    },
    props: {
        isDisabled: {
            default: false,
        },
    },
    data () {
        return {
            core: false,
            coreLayers: false,
            coreInterfaces: false,
            displayModal: false,
            otherCores: true,
            alertHeight: false,
            materials: [],
            isOpenMethodology: false,
            isOpenMaterialHelp: false,
            materialsByLayers: [],
            granulometriesByLayers: [],

            normalStatesField: [
                { id: 1, name: 'healthy_smooth' },
                { id: 2, name: 'fragmented' },
                { id: 3, name: 'cracked_smooth' },
                { id: 4, name: 'cracked_fragments_ripped_off' },
                { id: 5, name: 'mediocre_smooth' },
                { id: 6, name: 'mediocre_fragments_ripped_off' },
                { id: 7, name: 'crushed' },
            ],
            dirStatesField: [
                { id: 1, name: 'TTBO' },
                { id: 2, name: 'CTBO' },
                { id: 3, name: 'TBON' },
                { id: 4, name: 'CBON' },
                { id: 5, name: 'TMED' },
                { id: 6, name: 'CMED' },
                { id: 7, name: 'FEUIL' },
                { id: 8, name: 'FRAC' },
                { id: 9, name: 'TFAI' },
                { id: 10, name: 'CFAI' },
                { id: 11, name: 'MADI' },
            ],

            InterfacesNormalStatesField: [
                { id: 1, name: 'bonded' },
                { id: 2, name: 'semi-bonded' },
                { id: 3, name: 'unbonded' },
            ],
            InterfacesDirStatesField: [
                { id: 1, name: 'BACC' },
                { id: 2, name: 'MACC' },
                { id: 3, name: 'LFRA' },
                { id: 4, name: 'LFRA-RUPT' },
                { id: 5, name: 'DECO' },
                { id: 6, name: 'MADI' },
            ],
        };
    },
    computed: {
        ...mapGetters('coring', [
            'editingCore',
            'campaign',
            'displayNamePrefixes',
		 	'roads',
            'materialCategories',
            'otherMaterials',
            'layerNames',
            'cores',
        ]),

    },
    watch: {
        editingCore () {
            if (this.editingCore !== false) this.init();
        },
        'coreLayers': {
            deep: true,
            handler (newItem, oldItem) {
                this.checkTotalHeight();
            },
        },
    },
    methods: {
        ...mapActions('coring', [
            'updateRoads', 'refreshCore', 'updateLayerNames',
        ]),

        emitOpenClose () {
            this.isOpenMethodology = !this.isOpenMethodology;
            this.isOpenMethodology ? this.$emit('openMethodology') : this.$emit('closeMethodology');
        },

        async submit () {
            // Validation du formulaire
            if (this.$refs.form.validate()) {
                // Traitement des couches
                this.coreLayers = this.coreLayers.map((coreLayer) => {
                    if (typeof coreLayer.layerName !== 'string' && coreLayer.layerName !== null) {
                        coreLayer.layerName = coreLayer.layerName.name;
                    }
                    return coreLayer;
                });

                // Mise à jour via API
                await Promise.all([
                    this.$api.coreLayers.update(this.core.id, this.coreLayers),
                    this.$api.coreInterfaces.update(this.core.id, this.coreInterfaces),
                ]);

                // Mise à jour des noms de couches
                await this.updateLayerNames();

                /**
				 * In case of success, we only return data used by this form
				 * */
                return pick(this.core, [
                    'totalThicknessInMillimeters', 'piecesNumber', 'holeDepth', 'bottomNature', 'technicianNote',
                ]);
            } else {
                // Si la validation échoue, scroll vers le premier champ ayant une erreur
                this.scrollToFirstError();
                return false;
            }
        },
        scrollToFirstError () {
            // Cherche le premier élément invalide (v-input, v-textarea, ou v-select)
            const firstErrorElement = this.$el.querySelector(
                '.v-input input:invalid, .v-input textarea:invalid, .v-input select:invalid, .v-application .error--text',
            );

            if (firstErrorElement) {
                // Scrolle jusqu'à l'élément
                firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

                // Mettre le focus si possible
                const inputElement = firstErrorElement.querySelector('input, textarea, select');
                if (inputElement) {
                    inputElement.focus();
                } else {
                    // Pour les champs v-select, on ouvre le menu pour aider l'utilisateur
                    const vSelectElement = firstErrorElement.closest('.v-select');
                    if (vSelectElement && vSelectElement.__vue__) {
                        vSelectElement.__vue__.isMenuActive = true;
                    }
                }
            }
        },

        async init () {
            this.core = { ...this.editingCore };
            if (this.core.status === 'cancelled') return;

            let [coreLayers, coreInterfaces] =
            await Promise.all([
            	this.$api.coreLayers.get(this.core.id),
            	this.$api.coreInterfaces.getByCoreId(this.core.id),
            ]);

            this.coreInterfaces = coreInterfaces.sort((a, b) => a.number - b.number);
            this.coreLayers = coreLayers.sort((a, b) => a.number - b.number);

        },

        addLayer () {
            let lastLayerNumber = this.coreLayers.length + 1;

            if (lastLayerNumber > 1) {
                this.coreInterfaces = [
                    ...this.coreInterfaces,
                    {
                        id: null,
                        note: null,
                        number: lastLayerNumber - 1,
                        type: 'interface',
                        state: null,
                    },
                ];
            }

            this.coreLayers = [
                ...this.coreLayers, {
                    id: null,
                    state: null,
                    thickness: null,
                    year: null,
                    businessNumber: lastLayerNumber,
                    materialCategory: null,
                    material: null,
                    granulometry: null,
                    otherMaterial: null,
                    note: null,
                    number: lastLayerNumber,
                    type: 'layer',
                    cumulInMillimeters: null,
                    layerName: null,
                },
            ];

            this.setMaterials(this.coreLayers[this.coreLayers.length - 1]);
        },

        deleteLayer (layer) {
            for (let index = 0; index < this.coreLayers.length; index++) {
                if (layer.number === this.coreLayers[index].number) {
                    this.coreLayers.splice(index, 1);
                    index--;
                    if (layer.number === 1) {
                        this.deleteInterface(1);
                    } else {
                        this.deleteInterface(layer.number - 1);
                    }
                } else if (layer.number < this.coreLayers[index].number) {
                    this.coreLayers[index].number--;
                }
            }

            this.$forceUpdate();
        },

        deleteInterface (interfaceNumber) {
            for (let index = 0; index < this.coreInterfaces.length; index++) {
                if (interfaceNumber === this.coreInterfaces[index].number) {
                    this.coreInterfaces.splice(index, 1);
                    index--;
                } else if (interfaceNumber < this.coreInterfaces[index].number) {
                    this.coreInterfaces[index].number--;
                }
            }
        },

        isOtherCores () {
            if (this.cores.filter(e => e.status === 'sent_to_study_manager').length > 1) {
                this.otherCores = true;
            } else {
                this.otherCores = false;
            }
        },

        sortFields () {
            let scopedThis = this;
            this.normalStatesField.sort(function (a, b) {
                return scopedThis.$t(a.name).localeCompare(scopedThis.$t(b.name));
            });
            this.dirStatesField.sort(function (a, b) {
                return scopedThis.$t(a.name).localeCompare(scopedThis.$t(b.name));
            });
            this.InterfacesDirStatesField.sort(function (a, b) {
                return scopedThis.$t(a.name).localeCompare(scopedThis.$t(b.name));
            });
            this.InterfacesNormalStatesField.sort(function (a, b) {
                return scopedThis.$t(a.name).localeCompare(scopedThis.$t(b.name));
            });
        },

        checkTotalHeight () {
            let cumul = 0;
            this.coreLayers.forEach(layer => {
                if (layer.thickness !== null) {
                    cumul += parseInt(layer.thickness);
                }
            });

            this.alertHeight = cumul > this.core.totalThicknessInMillimeters;
        },
        setCategory (layer) {
            if (layer.material !== null) {
                layer.materialCategory = this.materialCategories.find(o => o.materials.find(material => material.id === layer.material.id));
                layer.granulometry = null;
            }

            this.setGranulometries(layer);
        },
        setMaterials (layer) {
            this.materialsByLayers[layer.number] = [];
            this.granulometriesByLayers[layer.number] = [];
            if (layer.materialCategory === null) {
                this.materialCategories.forEach(category => {
                    category.materials.forEach(material => {
                        this.materialsByLayers[layer.number].push({ id: material.id, name: material.name, granulometries: material.granulometries });
                    });
                });
            } else {
                let materialCategory = this.materialCategories.find(mc => mc.id === layer.materialCategory.id);
                materialCategory.materials.forEach(material => {
                    this.materialsByLayers[layer.number].push({ id: material.id, name: material.name, granulometries: material.granulometries });
                });
                this.setGranulometries(layer);
            }
            this.materialsByLayers[layer.number].sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        },
        setGranulometries (layer) {
            this.granulometriesByLayers[layer.number] = [];
            if (layer.material !== null) {
                let materialCategory = this.materialCategories.find(mc => mc.id === layer.materialCategory.id);
                let materials = materialCategory.materials.find(m => m.id === layer.material.id);
                materials?.granulometries.forEach(granulometrie => {
                    this.granulometriesByLayers[layer.number].push(granulometrie);
                });
                this.granulometriesByLayers[layer.number].sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            }
        },
    },
    created () {

    },
    async mounted () {
        await this.init();
        this.isOtherCores();
        this.sortFields();

        this.coreLayers.forEach(coreLayer => {
            this.setMaterials(coreLayer);
        });
    },

};
</script>

<style lang="scss">
div[coring-core-form-2]{

}
.v-text-field.v-text-field--enclosed .v-text-field__details{
	margin-bottom: 0px !important
}
</style>
