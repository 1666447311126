<template>
    <div
        v-if="core !== false"
        :style="{ '--bg-statut-color': color}"
        class="coring-core-card tw-p-[5px]"
    >
        <div class="coring-core-card__title">
            <h4>
                <span>
                    Carotte #{{ core.displayName ? core.displayName : core.number }}
                    <template v-if="core.displayName">
                        ({{ core.displayName }})
                    </template>
                </span>
                <span class="tooltip tw-flex tw-flex-col tw-overflow-hidden tw-h-full tw-justify-between">
                    <span class="text-ellipsis tw-flex tw-flex-row tw-items-center">
                        <span class="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]">
                            <template v-if="core.displayNamePrefix">
                                &nbsp;- {{ core.displayNamePrefix.name }} {{ core.displayName }}
                            </template>
                            <template v-else>
                                &nbsp;- {{ core.businessId }}
                            </template>
                        </span>
                    </span>
                    <span class="tooltiptext">
                        {{ core.displayNamePrefix ? core.displayNamePrefix.name + core.displayName : core.businessId }}
                    </span>
                </span>
            </h4>
        </div>

        <div class="coring-core-card__label">
            <span :title="infoStatusCore.label">
                <label :style="{'color': textColor}">
                    {{ label }}
                </label>
            </span>
        </div>
      
        <div class="coring-core-card__actions">
            <ButtonSlot
                v-if="infoStatusCore.label === 'Extraite'"
                _icon="mdi-check-outline"
                _full-rounded
                @click="setSendToManagerCore()"
                :_small="true"
                _theme="green"
                _tooltip="Valider l'extraction"
            />

            <ButtonSlot
                @click="map.jumpTo({center: [core.lng, core.lat]})"
                _icon="mdi-crosshairs-gps"
                _icon-size="22px"
                _theme="none"
                _full-rounded
                _small
            />

            <ButtonSlot
                @click="removeCore"
                v-if="campaign.status === 'awaiting_validation' && !isEditDisabled && $hasRight('campaigns.editCampaignBtn')"
                :_popup="{
                    title: 'Supprimer la carotte?',
                    subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
                }"
                _icon="mdi-delete"
                _icon-size="22px"
                _theme="none"
                _full-rounded
                _small
                _auto-loading
            />

            <ButtonSlot
                v-if="$hasRight('campaigns.editCampaignBtn') && (core.status === 'waiting' || core.status === 'extracted') && campaign.status === 'programing_validated'"
                @click="START_EDITING_CORE(core)"
                :_icon="'mdi-eye'"
                _icon-size="22px"
                _theme="none"
                _full-rounded
                _small
            />

            <ButtonSlot
                v-else-if="($hasRight('users.isCustomer') && core.status === 'waiting') || $hasRight('campaigns.editCampaignBtn')"
                @click="START_EDITING_CORE(core)"
                :_icon="'mdi-pencil'"
                _icon-size="22px"
                _theme="none"
                _full-rounded
                _small
            />

            <ButtonSlot
                v-if="!$hasRight('campaigns.editCampaignBtn') && core.status === 'waiting'"
                @click="validateCustomerCore(false)"
                _icon="mdi-close-thick"
                _icon-size="22px"
                _color="red"
                _theme="none"
                _full-rounded
                _small
                _tooltip="Invalider les informations d'implantation de la carotte"
            />

            <ButtonSlot
                v-if="!$hasRight('campaigns.editCampaignBtn') && core.status === 'waiting'"
                @click="validateCustomerCore(true)"
                _icon="mdi-check-bold"
                _icon-size="22px"
                _color="green"
                _theme="none"
                _full-rounded
                _small
                _tooltip="Valider les informations d'implantation de la carotte"
            />

            <ButtonSlot
                @click="cancelCore"
                :_popup="{
                    title: 'Annuler la carotte ?',
                    subTitle: 'CETTE ACTION EST IRRÉVERSIBLE',
                }"
                _icon="mdi-cancel"
                _icon-size="22px"
                _theme="none"
                _full-rounded
                _small
            />
        </div>  
        <div class="coring-core-card__core_metadata">
            {{ core.extractedAt ? `Prélevée le ${moment(core.extractedAt).format("DD/MM/Y")}` : `En attente d'extraction` }}
            <br />
            <span
                :style="core.corePictures.length === 0 ? 'color:red' : core.corePictures.length < 3 ? 'color: #fc9635' : 'color: green'"
                style="font-weight: 600;"
            >
                Photos : {{ core.corePictures.length }}
                <span
                    v-if="hasMissingPictures"
                    class="!tw-text-[rgb(234,179,8)]"
                >
                    <br />
                    ({{ missingPicturesLabel }})
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import coring from '../../utils/coring';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import corePicturesApi from '../../api/coring/corePictures';

export default {
    name: 'CoringCard',
    props: {
        core: {
            default: false
        }
    },
    data(){
        return {
            infoStatusCore: false,
        };
    },
    asyncComputed: {
        async missingPictures(){
            let missingPictures = {
                missingPicturesCount: 0,
                requiredMissingPicturesCount: 0,
            };

            await corePicturesApi.checkCorePictures(this.core.id)
                .then(result => {
                    missingPictures.missingPicturesCount = result.corePicturesFiles.filter(corePictureFile => !corePictureFile.pictureFileExists).length;
                    missingPictures.requiredMissingPicturesCount = result.missingRequiredTypes.length;
                })
            ;

            return missingPictures;
        },
    },
    computed: {
        ...mapGetters('coring', [
            'map', 'campaign', 'campaignStatus'
        ]),

        hasMissingPictures() {
            return (this.missingPictures?.missingPicturesCount ?? 0) > 0;
        },

        missingPicturesLabel() {
            if (!this.hasMissingPictures) {
                return '';
            }

            let label = `${this.missingPictures.missingPicturesCount} fichier${this.missingPictures.missingPicturesCount > 1 ? 's' : ''} manquant${this.missingPictures.missingPicturesCount > 1 ? 's' : ''}`;

            if (this.missingPictures.requiredMissingPicturesCount > 0) {
                label += `, dont ${this.missingPictures.requiredMissingPicturesCount} obligatoire${this.missingPictures.requiredMissingPicturesCount > 1 ? 's' : ''}`;
            }

            return label;
        },

        label(){
            if(this.campaign.status === 'awaiting_validation' && this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === null) return 'En attente';
            else if(this.campaign.status === 'awaiting_validation' && this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === true) return 'Validée';
            else if(this.campaign.status === 'awaiting_validation' && this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === false) return 'Invalidée';
            else if(this.campaign.status === 'awaiting_validation' && this.core.status === 'waiting') return 'A prélever';
            return this.infoStatusCore.label;
        },

        color(){
            if(this.campaign.status === 'awaiting_validation'){
                if(this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === null) return 'grey';
                if(this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === true) return 'green';
                if(this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === false) return 'red';
                if(this.core.status === 'waiting') return 'green';
                if(this.core.status === 'validated') return 'green';
                if(this.core.status === 'empty') return 'orange';
                if(this.core.status === 'cancelled') return 'red';
            }
            else if(this.campaign.status === 'programing_validated'){
                if(this.core.status === 'waiting') return 'orange';
                if(this.core.status === 'validated') return 'green';
                if(this.core.status === 'sent_to_study_manager') return 'green';
                if(this.core.status === 'cancelled') return 'red';
            }
            else if(this.campaign.status === 'taken'){
                if(this.core.status === 'validated') return 'green';
                if(this.core.status === 'sent_to_study_manager') return 'orange';
                if(this.core.status === 'cancelled') return 'red';
            }
            else if(this.campaign.status === 'taking_validated'){
                if(this.core.status === 'results_obtained') return 'green';
                if(this.core.status === 'validated') return 'orange';
                if(this.core.status === 'sent_for_analysis') return 'green';
                if(this.core.status === 'cancelled') return 'red';
            }
            return '#D8D8D8';
        },
        textColor(){
            return this.color === '#D8D8D8' ? 'black' : 'white';
        },
        isEditDisabled(){
            if(this.campaign.status === 'taken' && this.core.status !== 'sent_to_study_manager' && this.core.status !== 'validated'){
                return true;
            }
            if(this.campaign.status === 'awaiting_validation' && this.core.status !== 'empty' && this.core.status !== 'waiting'){
                return true;
            }
            return false;
        }
    },
    watch: {
        core(){
            if(this.core !== false){
                this.init();
            }
        }
    },
    methods: {
        ...mapMutations('coring', ['START_EDITING_CORE']),
        ...mapActions('coring', ['deleteCore', 'forceUpdateCores']),

        removeCore(){
            this.deleteCore(this.core).then(e => {
                this.forceUpdateCores(this.campaign.id);
            });
        },

        init(){
            this.infoStatusCore = coring.getInfoStatusCore(this.core);
        },
        validateCustomerCore(isValidated){
            this.$api.cores.patch({id: this.core.id, validateCustomer: isValidated}).then(e => {
                this.forceUpdateCores(this.campaign.id);
            });
        },
        setSendToManagerCore(){
            this.$api.cores.patch({id: this.core.id, status: 'sent_to_study_manager'}).then(e => {
                this.forceUpdateCores(this.campaign.id);
            });
        },
        cancelCore(){
            this.$api.cores.patch({id: this.core.id, status: 'cancelled'}).then(e => {
                this.forceUpdateCores(this.campaign.id);
            });
        }
    },
    created(){
        this.init();
    }
    
};
</script>

<style lang="scss">
.coring-core-card {
    overflow: hidden;
    user-select: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, minmax(1px, auto));
    grid-template-rows: repeat(3, auto);

    &__title {
        grid-column: 1/-1;

      h4 {
        display: inline-flex;
      }
    }

    &__label {
        label {
            background: var(--bg-statut-color);
            color: white;
            gap: 5px;
            padding: 2px 7px;
            border-radius: 999px;
            font-size: 12px;
            overflow: hidden;
            min-width: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-shrink: 1;

            &::before{
                position: fixed;
                content: attr(tooltip);
            }
        }
    }

    &__core_metadata {
        font-size: 10px;
        grid-column: 1/-1;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;

    .tooltiptext {
        visibility: hidden;
        width: max-content;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: -5px; /* Ajustez la distance par rapport au texte coupé */
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}
</style>
